import {Code} from '@code-like-a-carpenter/components';

export interface ExampleProps {
  label?: string;
  report?: string;
  root?: string;
  sha?: string;
  token: string;
}

export function Example({
  label = 'Unit Tests',
  report = './reports/junit.xml',
  root = '$(pwd)',
  sha = '$CIRCLE_SHA1',
  token,
}: ExampleProps) {
  const str = `curl "https://api.check-run-reporter.com/api/v1/submissions" \\
  --user token:${token} \\
  -X POST \\
  -F report=@"${report}" \\
  -F label="${label}" \\
  -F sha="${sha}" \\
  -F root="${root}"`;

  return <Code dedent={false}>{str}</Code>;
}
